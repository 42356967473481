import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import  programData  from "../data/program.json";

export function ElitePrograms(props){
    const[programDetails, setProgramDetails] = useState({});
    const[programCurriculum, setProgramCurriculum] = useState([]);
    const params = useParams();
    const [activeCurriculum, setActiveCurriculum] = useState(null);


    // images curtains animation ///////
    const overlayRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        const rect = overlayRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // images curtains animation //////////

    function loadProgramData(){
        var selectedData = programData.find(item => item.id === params.programId);
        if(selectedData){
            setProgramDetails(selectedData);
            setProgramCurriculum(selectedData.accordion)
        }
    } 

    let currentIndex = 0;
    function handleCertificateSlide() {
        const slides = document.querySelectorAll(".certificate-slide-img-container img");
        const dots = document.querySelectorAll(".btn-certificate-slide span");
        currentIndex = (currentIndex + 1) % slides.length;
        const offset = -currentIndex * 100;
        slides.forEach(slide => {
            slide.style.transform = `translateX(${offset}%)`;
            slide.style.transition = "transform 0.5s ease-in-out";
        });
        dots.forEach((dot, index) => {
            dot.className = index === currentIndex ? "active-dott" : "unactive-dott";
        });
    }
    setInterval(handleCertificateSlide, 6000);

    useEffect(()=>{
        loadProgramData();
    },[params]) 

    useEffect(()=>{
        window.scrollTo(0,0);
    },[programDetails])

    function handleProgramRegistration(){
        document.getElementById("btn-green-tag").style.transform = "translateX(86%)";
        document.getElementById("btn-green-tag").style.backgroundColor="red"
        document.getElementById("btn-green-tag").style.transition="0.7s ease-out";

        setTimeout(function() {
            window.open(`https://subscription.rinex.ai/subscribe/6553506cc3e6bb7ada6a0be7/one-time-900-link`, "_blank")
        }, 600);

        setTimeout(function() {
            document.getElementById("btn-green-tag").style.transform = "translateX(0%)";
            document.getElementById("btn-green-tag").style.backgroundColor = programDetails.regButtonClr;
            document.getElementById("btn-green-tag").style.transition="0s"
        }, 1000);
    }

    async function handleFormSubmit(e){
        e.preventDefault();
        const scriptURL = "https://script.google.com/macros/s/AKfycbyth-koBq6UH7A5r0w0J9fcaQCfWvAo718H04RLRCZ-ZwT3_jsBt_k8gpLa8XYash83mA/exec"
        const form = document.forms["contact-details"];
        document.getElementById("btnSubmitText").style.transform="translate(93%)";
        setTimeout(function() {
            document.getElementById("btnSubmitText").innerHTML="Submited";
            document.getElementById("RegButton").style.backgroundColor=programDetails.regButtonClr
        }, 600);
        setTimeout(function() {
            document.getElementById("myForm").reset();
            window.location.reload();
        }, 1000);
        
        document.getElementById("btnSubmitText").style.transition="0.9s ease-in-out";
        await fetch(scriptURL, {method: "POST", body: new FormData(form)})
        .catch(error => console.error("Error!", error.message));
    }

    const handleTogglecurriculum = (index) => {
        if (activeCurriculum === index) {
            setActiveCurriculum(null)
        } else {
            setActiveCurriculum(index)
        }
    }
    return(
        <React.Fragment>
            <p>{params.field}</p>
            {/* progrma title banner started ----- */}
            <div className="program-title-banner-section">
                <div className="program-banner-container" style={{backgroundImage:`url(${programDetails.banner})`}}>
                    <img className="certificate-chip" src="/images/certificate_chip.svg" alt="" />
                    <p className="program-banner-para">{programDetails.programField}</p>
                    <h6 className="program-banner-heading">{programDetails.programTitle}</h6>
                    <p className="progrma-banner-para2">Trained by Industry professionals from</p>
                    <div className="banner-company-logo">
                        <div>
                            <img src={programDetails.companyLogo1} alt="" />
                            <img src={programDetails.companyLogo2}  alt="" />
                        </div>
                        <p className="stipend-para">₹{programDetails.stipendPrice}· HIGH STIPEND OFFERED*</p>
                    </div>
                </div>

                {/* --------------- */}
                <div className="program-registration-container">
                    <div className="program-duration-card">
                        <img src="/images/clock_green.svg" alt="" />
                        <div>
                            <p className="program-duration-para">DURATION</p>
                            <h6 className="program-duration-heading">{programDetails.programDuration} Months · Online</h6>
                        </div>
                    </div>

                    <div className="program-type-card">
                        <img src="/images/cap_green.svg" alt="" />
                        <div>
                            <p className="program-duration-para">PROGRAM</p>
                            <h6 className="program-duration-heading">Live classes</h6>
                        </div>
                    </div>

                    <div className="program-guaranteed-card">
                        <img src="/images/lightning_green.svg" alt="" />
                        <div>
                            <p className="program-duration-para">GUARNTEED</p>
                            <h6 className="program-duration-heading">Skills</h6>
                        </div>
                    </div>
                    <button onClick={handleProgramRegistration} className="btn-program-registration">
                        <div id="btn-green-tag" style={{backgroundColor:`${programDetails.regButtonClr}`}}>Register ₹{programDetails.programFees}</div>
                        <img src="/images/right_arrows.svg" alt="" />
                    </button>
                </div>
            </div>
            {/* progrma title banner ended ----- */}


            {/* programm highlights section started----- */}
            <div className="program-highlight-section">
                <p className="program-highlight-para">
                    ABOUT PROGRAM
                </p>
                <h6 className="program-highlights-heading">Program Highlights</h6>

                <div className="program-highlight-card-container">
                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">LEARN ONLINE</p>
                            <h6 className="program-highlight-heading">At your own<br></br> Schedule</h6>
                        </div>
                        <img src="/images/calender_yellow.svg" alt="" />
                        <img className="circle-1" src="/images/yellow_circle.svg" alt="" />
                        <img className="circle-2" src="/images/yellow_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">MOBILE FREINDLY</p>
                            <h6 className="program-highlight-heading">No laptop? <br></br>No problem</h6>
                        </div>
                        <img src="/images/mobile_green.svg" alt="" />
                        <img className="circle-1" src="/images/green_circle.svg" alt="" />
                        <img className="circle-2" src="/images/green_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">PLACEMENT ASSISTANCE</p>
                            <h6 className="program-highlight-heading">To build your<br></br> Career</h6>
                        </div>
                        <img src="/images/bag_blue.svg" alt="" />
                        <img className="circle-1" src="/images/blue_circle.svg" alt="" />
                        <img className="circle-2" src="/images/blue_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">CERTIFICATE OF TRAINING</p>
                            <h6 className="program-highlight-heading">From Rinex <br></br>trinings</h6>
                        </div>
                        <img src="/images/medal_purple.svg" alt="" />
                        <img className="circle-1" src="/images/purple_circle.svg" alt="" />
                        <img className="circle-2" src="/images/purple_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">1 PROJECT & 4 ASSIGNMENTS</p>
                            <h6 className="program-highlight-heading">For hands <br></br>on Practice</h6>
                        </div>
                        <img src="/images/dumbell_orange.svg" alt="" />
                        <img className="circle-1" src="/images/orange_circle.svg" alt="" />
                        <img className="circle-2" src="/images/orange_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">DOUBT CLEARING</p>
                            <h6 className="program-highlight-heading">Through<br></br>Q&A Forum</h6>
                        </div>
                        <img src="/images/question_green.svg" alt="" />
                        <img className="circle-1" src="/images/green_circle.svg" alt="" />
                        <img className="circle-2" src="/images/green_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">LEARN IN HINDI OR ENG</p>
                            <h6 className="program-highlight-heading">As per your<br></br>choice</h6>
                        </div>
                        <img src="/images/medal_green.svg" alt="" />
                        <img className="circle-1" src="/images/lightgreen_circle.svg" alt="" />
                        <img className="circle-2" src="/images/lightgreen_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">BEGINNER FRIENDLY</p>
                            <h6 className="program-highlight-heading">NO prior <br></br>knowledge required</h6>
                        </div>
                        <img src="/images/star_yellow.svg" alt="" />
                        <img className="circle-1" src="/images/yellow_circle.svg" alt="" />
                        <img className="circle-2" src="/images/yellow_circle.svg" alt="" />
                    </div>

                    <div className="program-highlight-card">
                        <div>
                            <p className="program-highlight-card-para">8 WEEKS DURATION</p>
                            <h6 className="program-highlight-heading">1Hr/ day.<br></br>Flexible Schedule</h6>
                        </div>
                        <img src="/images/calendar_green.svg" alt="" />
                        <img className="circle-1" src="/images/lightgreen_circle.svg" alt="" />
                        <img className="circle-2" src="/images/lightgreen_circle.svg" alt="" />
                    </div>
                </div>
            </div>
            {/* programm highlights section ended----- */}

 
            {/* program-career section started ---- */}
            {/* Experts section started ---- copied from home page  */}
            <div className="expert-section">
                <div className="expert-container">
                    <div className="expert-container-left-side">
                        <p className="expert-section-para">CAREER</p>
                        <h5 className="expert-section-heading">Top companies hiring in <br></br>Rinex</h5>
                        <p className="expert-section-para-grey">Secure your future with our best hiring companies from all over the world.</p>
                    </div>
                    <div className="expert-container-right-side">
                        <img data-aos="fade-left" data-aos-duration="2000"  src="/images/logo_row_1.png" alt="" />
                        <img data-aos="fade-left" data-aos-duration="2000" data-aos-delay="200" src="/images/logo_row_2.png" alt="" />
                        <img data-aos="fade-left" data-aos-duration="2000" data-aos-delay="300" src="/images/logo_row_3.png" alt="" />
                        <img data-aos="fade-left" data-aos-duration="2000" data-aos-delay="700" src="/images/expert_bg.png" alt="" />
                    </div>
                </div>
            </div>
            {/* Experts section ended ---- */}
            {/* program-career section ended ---- */}


            {/* program certificate  section started ---- */}
            <div className="program-certificate-section">
                <div className="program-certificate-container">
                    <div className="program-certificate-left-side">
                        <p className="program-certificate-para">CERTIFICATE</p>
                        <h6 className="program-certificate-heading">The certification of Rinex</h6>
                        <p className="program-certificate-para-grey">Rinex education certificates are recognitions and honor of your hardwork.</p>
                    </div>

                    <div className="program-certificate-right-side">
                        <div className="certificate-slide-img-container">
                            <img id="slide-1" src="/images/certificate-1.png" alt="" />
                            <img id="slide-2" src="/images/certificate-2.png" />
                            <img id="slide-3" src="/images/certificate-3.png" alt="" />

                            <button onClick={handleCertificateSlide} className="btn-certificate-slide">
                                <span className="active-dott" id="slideDott-1"></span>
                                <span className="unactive-dott" id="slideDott-2"></span>
                                <span className="unactive-dott" id="slideDott-3"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* program certificate  section ended ---- */}

            {/* placement-salary section started ------- */}
            <div className="placement-salary-section">
                <div className="salary-card">
                    <p className="salary-card-para">AVG CTC</p>
                    <h6 className="salary-card-heading">35-40 Lakhs/Year</h6>
                    <p className="salary-card-para-grey">An ML professional with 5+ Years of experience is paid an average salary of 35-50 LPA.</p>
                    <img src="/images/graph.svg" alt="" />
                    <div  className={`image-curtains ${isVisible ? 'animate' : ''}`} ref={overlayRef}></div>
                    <p className="fiveyearEx">23 Lakh Jobs · 2022</p>
                    <p className="lessEx">8 Lakh Jobs · 2020</p>
                </div>

                <div className="job-card">
                    <p className="salary-card-para">JOB</p>
                    <h6 className="salary-card-heading">25 Lakhs Jobs</h6>
                    <p className="salary-card-para-grey">As per Gartner, Artificial Intelligence & Machine learning will create 23 lakh job opening by 2021-22.</p>
                    <img src="/images/range.svg" alt="" />
                    <div className={`image-curtains ${isVisible ? 'animate' : ''}`} ref={overlayRef}></div>
                    <p className="range-para-1">23 Lakh Jobs · 2022</p>
                    <p className="range-para-2">8 Lakh Jobs · 2020</p>
                </div>

                <div className="lift-card">
                    <p className="salary-card-para">LIFT</p>
                    <h6 className="salary-card-heading">Analytics Infra 5x Lift</h6>
                    <p className="salary-card-para-grey">By 2024, 75% enterprises will shift to operationalizing AI, Driving 5x increase in analytics infrastructures.</p>
                    <div className="lift-card-anime-container">
                        <img src="/images/ai_alpha.svg" alt="" />
                        <img src="/images/ai_left_cloud.png" alt="" />
                        <img src="/images/ai_right_cloud.png" alt="" />
                        <img src="/images/ai_texture.png" alt="" />
                        <p>Artificial Intteligence</p>
                    </div>
                </div>
            </div>
            {/* placement-salary section ended ------- */}

            {/* refer section started ------ */}
            <div className="refer-section">
                <div className="refer-section-container">
                    <p className="refer-para">REFER</p>
                    <h6 className="refer-heading">Refer someone and Earn upto ₹500</h6>
                    <p className="refer-para-grey">Cashback/Vouchers, on every successful enrollment.</p>

                    <button className="btnRefer">Start Refering</button>
                </div>
            </div>
            {/* refer section ended ------ */}

            {/* program details section started ------ */}
            <div className="program-details-section">
                <div className="program-details-head">
                    <div>
                        <p className="program-details-para">PROGRAM</p>
                        <h6 className="program-details-heading">{programDetails.programDetailsTitle}</h6>
                    </div>
                    <a onMouseOver={props.addSmoothScrolling} onMouseOut={props.removeSmoothScrolling} style={{textDecoration:"none"}} href="#formContact" className="btnDownloadBrochure"><img src="/images/download_icon.svg" alt="" />Download Brochure</a>
                </div>

                <div className="program-details-card-container">
                    <div className="program-content-card">
                        <div>
                            <p className="program-content-para">CONTENT</p>
                            <h6 className="program-content-heading">60+ Hours of Contents</h6>
                        </div>
                        <img src={programDetails.contentIcon} alt="" />
                    </div>

                    <div className="program-content-card">
                        <div>
                            <p className="program-content-para">KNOWLEDGE</p>
                            <h6 className="program-content-heading">Practical Assessments</h6>
                        </div>
                        <img src={programDetails.notebookIcon} alt="" />
                    </div>

                    <div className="program-content-card">
                        <div>
                            <p className="program-content-para">PROJECTS</p>
                            <h6 className="program-content-heading">4 Industry level projects</h6>
                        </div>
                        <img src={programDetails.folderIcon} alt="" />
                    </div>
                </div>
            </div>
            {/* program details section ended ------ */}

            {/* curriculum accordion section started ---- */}
            <div className="curriculum-accordion-section">
                <div className="curriculum-container">
                    <div className="curriculum-accordion-head">
                        <p className="curriculum-para">CURRICULUM</p>
                        <h6 className="curriculum-heading">Know your course curriculum</h6>
                    </div>

                    <div className="curriculum-content">
                        {programCurriculum.map((item, index) =>
                            <div className="curriculum-btn" key={index}>
                                <div>
                                    <div  className="curriculum-title" onClick={() => handleTogglecurriculum(index)}>
                                        <div> 
                                            <p>Curriculum {index + 1}</p>
                                            <p>{item.content}</p>
                                        </div>
                                        <p>{activeCurriculum === index ? `-` : `+`}</p>
                                    </div>
                                </div>

                                <div>
                                    <div style={{marginBottom:"10px"}} className={activeCurriculum === index ? 'curriculum-content-text open' : "curriculum-content-text"}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                            )}
                    </div>
            
                    <div id="formContact"></div>

                    <img className="hor-divider" src="/images/horizontal_divider.svg" alt="" />


                    <div className="curriculum-accordion-foot">
                        <div>
                            <p className="curriculum-foot-para">REGISTER NOW</p>
                            <h6 className="curriculum-foot-heading">Like the curriculum ?</h6>

                            <form id="myForm" className="curriculum-form" onSubmit={handleFormSubmit} name="contact-details">
                                <input name="name" required type="text" id="txtName" placeholder="Full Name"/>

                                <input name="college" required type="text" id="txtCollegeName"  placeholder="College Name"/>

                                <div className="phone-number-container">
                                    <div className="country-code">+91</div>
                                    <input name="number" required type="text" id="txtPhoneNumber" placeholder="Phone Number" />
                                </div>

                                <button id="RegButton" className="btnRegisterCurriculum">
                                    <div  style={{backgroundColor:`${programDetails.regButtonClr}`}} className="btnGreenTag" id="btnSubmitText">Submit</div>
                                    <img src="/images/right_arrows.svg" alt="" />
                                </button>
                            </form>
                        </div>

                        <div>
                           <img alt="" src={programDetails.formImage} />
                           <img data-aos="flip-left" data-aos-duration="1000" src="" alt=""/>
                           <img data-aos="flip-right" data-aos-duration="4000" src="" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* curriculum accordion section started ---- */}

            {/* contacts card section started ------ */}
            <div className="contact-card-section" style={{marginBottom:"40px"}}>
                <a href="/#demoNav" style={{textDecoration:"none"}} className="contact-card1">
                    <div>
                        <p className="contact-card-para">DEMO</p>
                        <h5 className="contact-card-heading">Request a free demo</h5>
                        <div className="contact-card-img-1">
                            <div>
                                <img className="btn-request" src="" alt="" />
                                <img className="hand-logo" src="" alt="" />
                                <img className="layer-1" src="/images/request_layer_4.svg" alt="" />
                                <img className="layer-2" src="/images/request_layer_3.svg" alt="" />
                                <img className="layer-3" src="/images/request_layer_2.svg" alt="" />
                                <img className="layer-4" src="/images/request_layer_1.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <p className="request-para">Request <img src="/images/green_arrow.svg" alt="" /></p>
                </a>

                <a href="tel:+917892745201" style={{textDecoration:"none"}} className="contact-card2">
                    <div>
                        <p className="contact-card-para">CALL US</p>
                        <h5 className="contact-card-heading">Have doubt? Call Us</h5>
                        <div className="contact-card-img-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <img src="images/call_logo.svg" alt="" />
                        </div>
                    </div>
                    <p className="contact-num-para"> +91 78927 45201 <img src="/images/blue_arrow.svg" alt="" /></p>
                </a>

                <a  href="mailto:help@rinex.ai" style={{textDecoration:"none"}} className="contact-card3">
                    <div>
                        <p className="contact-card-para">QUERY</p>
                        <h5 className="contact-card-heading">Have query? Mail Us</h5>
                        <div className="contact-card-img-3">
                            <img className="blue-chat" src="" alt="" />
                            <img className="white-chat" src="" alt="" />
                        </div>
                    </div>
                    <p className="email-para">help@rinex.ai <img src="/images/darkblue_arrow.svg" alt="" /></p>
                </a>
            </div>
            {/* contacts card section ended ------ */}
        </React.Fragment>
    )
}